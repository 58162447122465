<template>
<div id="mine">
  <div class="mineWrap">
    <div class="personal-left">
      <div class="position-tab">
        <ul>
          <li @click="toggle('ALL')" :class="{activeTab:positionStatus=='ALL'}">全部</li>
          <li @click="toggle('1')" :class="{activeTab:positionStatus=='1'}">开放中</li>
          <li @click="toggle('0')" :class="{activeTab:positionStatus=='0'}">待开放</li>
          <li @click="toggle('2')" :class="{activeTab:positionStatus=='2'}">审核失败</li>
          <li @click="toggle('4')" :class="{activeTab:positionStatus=='4'}">已关闭</li>
        </ul>
      </div>
      <div class="positionList" v-if="positionList.length>0" v-loading="loading">
        <div class="positionItem" @click="jumpPosition(item.id)" v-for="(item,index) in positionList" :key="index">
          <h3>
            <span>{{item.positionName}}</span>
            <label v-show="item.positionStatus==0">待开放</label>
            <label v-show="item.positionStatus==1">开放中</label>
            <label v-show="item.positionStatus==2">审核失败</label>
            <label v-show="item.positionStatus==3">审核中</label>
            <label v-show="item.positionStatus==4">已关闭</label>
          </h3>
          <p>
            <label><img width="20px" height="20px" src="@/assets/imgs/time-icon.png">{{item.workExperienceName}}</label>
            <label><img width="23px" height="21px" src="@/assets/imgs/edu-icon.png">{{item.minimumEducationName}}</label>
            <label>
              <img width="20px" height="20px" src="@/assets/imgs/personal/money_bag.png">
              <span>
                {{item.minimumWage}}<label v-show="item.maximumSalary">-{{item.maximumSalary}}</label>
              </span>
            </label>
          </p>
        </div>
      </div>
      <el-empty v-else :image-size="200" description="暂时没有符合该搜索条件的职位"></el-empty>
      <div class="publish-btn" @click="jumpPublish">发布职位</div>
    </div>
    <div class="personal-right">
      <div class="user-sider">
          <router-link :to="{path:'/account'}">
            <img class="avator" :src="userInfo.headSculpture">
            <div class="u-name">
              <h5>{{userInfo.userName}}</h5>
              <p>
                <img src="@/assets/imgs/personal/phoneIcon.png" /><span>{{userInfo.phone}}</span>
              </p>
              <p>
                <img src="@/assets/imgs/personal/setIcon.png" /><label>账户设置</label>
              </p>
            </div>
          </router-link>
          <div class="user-stat">
            <router-link :to="{path:'/chat'}">
              <p>
                <span>{{enterpriseNum.communicateNum}}</span>
                <label>沟通过</label>
              </p>
            </router-link>
            <p @click="jumpInvite">
              <span>{{enterpriseNum.interviewNum}}</span>
              <label>邀请面试</label>
            </p>
            <p @click="jumpResume">
              <span>{{enterpriseNum.enterpriseReceivingNum}}</span>
              <label>已收投递</label>
            </p>
            <p  @click="jumpCollect">
              <span>{{enterpriseNum.favoriteUsersNum}}</span>
              <label>收藏</label>
            </p>
          </div>
      </div>
      <div class="publish" @click="jumpPublish">
        <img src="@/assets/imgs/personal/publish.png" />
        <span>发布职位</span><label>{{positionNum}}个在招职位</label>
      </div>
      <div class="publish refresh">
        <img src="@/assets/imgs/personal/refresh.png" />
        <span>刷新职位</span><label>提升简历活跃度</label>
      </div>
      <router-link :to="{path:'/shop'}">
        <div class="resume-online">
          <h4><span>道具商城</span>
            <label>查看</label>
          </h4>
        </div>
      </router-link> 
      <router-link :to="{path:'/license'}">
        <div class="resume-online">
          <h4><span>营业执照</span>
            <label>编辑</label>
          </h4>
        </div>
      </router-link>
      <div class="footmark">
        <ul>
          <li @click="jumpCollect">
            <span>我的收藏</span><i class="el-icon-arrow-right"></i>
          </li>
          <li @click="jumpFooter">
            <span>浏览足迹</span><i class="el-icon-arrow-right"></i>
          </li>
          <li @click="jumpSeen">
            <span>谁看过我</span><i class="el-icon-arrow-right"></i>
          </li>
          <li @click="jumpMap">
            <span>企业设置地图</span><i class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getEnterprisePositionList,getEnterprisePersonalCenterResponse,verifyRealName,getRecruitmentPositionNum,getRecruiterEnterpriseDetails } from "@/api/index.js";
export default {
  name: "mine",
  data() {
    return {
      userInfo: {},
      positionStatus: 'ALL',
      positionList: [],
      loading:true,
      compnayInfo:{},
      positionNum:0,
      enterpriseNum:{}
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getEnterprisePositionList()
    this.verifyRealName()
    this.getRecruiterEnterpriseDetails()
    this.getEnterprisePersonalCenterResponse()
  },
  methods: {
    getEnterprisePersonalCenterResponse(){
      getEnterprisePersonalCenterResponse().then(res=>{
        this.enterpriseNum = res.data
      })
    },
    jumpInvite(){
      this.$router.push({
        path:'/account',
        query:{
          type:6
        }
      })
    },
    jumpResume(){
      this.$router.push({
        path:'/account',
        query:{
          type:7
        }
      })
    },
    getRecruiterEnterpriseDetails(){
      getRecruiterEnterpriseDetails().then(res=>{
        this.compnayInfo = res.data
        getRecruitmentPositionNum({
          enterpriseId: this.compnayInfo.enterpriseId
        }).then(res=>{
          this.positionNum = res.data.positionNum
        })
      })
    },
    verifyRealName(){
      verifyRealName().then(res=>{
        if(res.isCertification==0){
          this.$router.push({
            path:'/addContacts'
          })
        }
      })
    },
    jumpCollect(){
      this.$router.push({
        path:'/account',
        query:{
          type:2
        }
      })
    },
    jumpFooter(){
      this.$router.push({
        path:'/account',
        query:{
          type:3
        }
      })
    },
    jumpSeen(){
      this.$router.push({
        path:'/account',
        query:{
          type:4
        }
      })
    },
    jumpMap(){
       this.$router.push({
        path:'/account',
        query:{
          type:8
        }
      })
    },
    getEnterprisePositionList() {
      this.loading = true
      getEnterprisePositionList({
        positionStatus: this.positionStatus
      }).then(res => {
        this.loading = false
        this.positionList = res.rows
      })
    },
    jumpPublish() {
      if (this.userInfo.idCard) {
        this.$router.push({
          path: '/publish'
        })
      } else {
        this.$message({
          message: '请先实名认证！',
          type: 'warning'
        })
        this.$router.push({
          path: '/account'
        })
      }
    },
    jumpPosition(id) {
      this.$router.push({
        path: '/jobDetail',
        query: {
          positionId: id
        }
      })
    },
    toggle(index) {
      this.positionStatus = index
      this.getEnterprisePositionList()
    }
  }
};
</script>

<style lang="scss" scoped>
#mine {
  width: 100%;
  min-height: 650px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}

.mineWrap {
  width: 1200px;
  margin: 24px auto;
}

.position-tab {
  width: 780px;
  height: 55px;
  line-height: 55px;
  background: #FFFFFF;

  ul {
    display: flex;
  }

  li {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #222222;
    cursor: pointer;
    margin: 0 20px;
  }

  .activeTab {
    color: #0077FF;
    border-bottom: 3px solid #0077FF;
  }
}

.positionList {
  .positionItem {
    width: 744px;
    min-height: 56px;
    cursor: pointer;
    margin-top: 10px;
    padding: 20px 20px 25px;
    background: #FFFFFF;
    border-radius: 10px;

    h3 {
      span {
        font-size: 18px;
        font-weight: 500;
        color: #222222;
      }

      label {
        display: block;
        float: right;
        font-size: 18px;
        font-weight: 500;
        color: #FF2400;
      }
    }

    p {
      margin-top: 12px;

      img {
        position: relative;
        top: 4px;
        margin-right: 10px;
      }

      label {
        display: inline-block;
        font-size: 14px;
        color: #999999;
        margin-right: 60px;
      }
    }
  }
}
.resume-online {
  width: 362px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 13px;
  padding: 25px 20px;
  clear: both;

  h4 {
    margin-bottom: 27px;

    span {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
    }

    label {
      display: block;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      margin-top: 4px;
      cursor: pointer;
    }
  }
}
.publish-btn {
  width: 663px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  background: #0077FF;
  border-radius: 5px;
  margin: 43px auto;
  cursor: pointer;
}

.personal-left {
  float: left;
}

.personal-right {
  float: left;
  margin-left: 15px;
  width: 400px;
}

.user-sider {
  width: 362px;
  height: 190px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px 20px;

  .avator {
    width: 110px;
    height: 110px;
    float: left;
  }

  .u-name {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 18px;

    img {
      width: 24px;
      height: 24px;
      position: relative;
      top: 6px;
      margin-right: 10px;
    }

    h5 {
      font-size: 20px;
      font-weight: 500;
      color: #2A2C33;
    }

    p {
      margin-top: 5px;

      span {
        font-size: 16px;
        color: #999999;
      }

      label {
        font-size: 16px;
        color: #222222;
      }
    }
  }

  .user-stat {
    clear: both;
    border-top: 1px solid #DDDDDD;
    height: 86px;

    p {
      float: left;
      text-align: center;
      width: 25%;
      padding-top: 17px;
      cursor: pointer;

      span {
        font-size: 20px;
        font-weight: 500;
        color: #222222;
      }

      label {
        display: block;
        margin-top: 10px;
        font-size: 16px;
        color: #999999;
      }
    }

  }
}

.publish {
  width: 195px;
  height: 90px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  background: #FFFFFF;
  border-radius: 10px;
  cursor: pointer;

  img {
    width: 45px;
    height: 45px;
    margin: 23px 12px 23px 10px;
    float: left;
  }

  span {
    font-size: 19px;
    font-weight: 500;
    color: #222222;
    display: inline-block;
    margin-top: 19px;
  }

  label {
    font-size: 14px;
    display: block;
    color: #999999;
    margin-top: 5px;
  }
}

.refresh {
  margin-left: 10px;
}

.footmark {
  width: 362px;
  min-height:75px;
  clear: both;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 10px;
  padding: 25px 20px 0;
  margin-bottom: 26px;

  li {
    height: 50px;
    cursor: pointer;

    i {
      float: right;
    }

    span {
      font-size: 18px;
      color: #222222;
    }
    span:hover{
      color: #0077FF;
      transition: all linear .2s;
    }
    label {
      display: block;
      float: right;
      font-size: 18px;
      color: #666666;
      margin-right: 10px;
    }
  }
}
</style>
